<template>
  <Banner href="/news/as-cohere-and-writer-mine-the-live-ai-arena-pathway-joins-the-pack-with-a-10m-round"
    v-if="path == '/'">
    <span class="font-bold">In the Spotlight:</span> Pathway secures $10 Million led by TQ Ventures to advance the
    development of Live AI. Read it on TechCrunch.
  </Banner>
  <header
    class="sticky top-0 z-30 w-full h-header bg-white backdrop-filter backdrop-blur-lg bg-opacity-90 px-2 nav-header">
    <div class="flex items-center h-full justify-between max-w-7xl mx-auto">
      <NavbarLogo />
      <nav v-if="isDevelopers" class="mx-8 hidden md:block">
        <ul class="hidden md:flex gap-4 items-center">
          <li class="px-1 ">
            <nuxt-link to="/developers/user-guide/introduction/welcome"
              :class="{ 'router-link-active': $route.path.includes('/developers/user-guide/') }"
              class="hover:underline font-medium flex gap-1 items-center whitespace-nowrap bg-gray-50 px-2 py-1 rounded-md group transition hover:bg-gray-100">
              <span
                class="bg-gray-100 p-2 rounded-lg  flex gap-2 items-center group transition hover:text-primary-500 group-hover:bg-primary-100 icon">
                <Icon name="heroicons:cpu-chip-20-solid" />
              </span>
              <span>
                Pathway Framework
              </span>
            </nuxt-link>
          </li>
          <li class="px-1 ">
            <NuxtLink to="/developers/templates"
              :class="{ 'router-link-active': $route.path.includes('/developers/templates/') }" class=" hover:underline font-medium flex gap-2 items-center whitespace-nowrap bg-gray-50 px-2 py-1
              rounded-md group transition hover:bg-gray-100">
              <span
                class="p-2 rounded-lg flex items-center group transition  group-hover:bg-primary-100 bg-gray-100 icon">
                <Icon name="heroicons:bolt-20-solid" />
              </span>
              <span>
                Pathway Templates
              </span>
            </NuxtLink>
          </li>
        </ul>
      </nav>
      <NavbarCenter v-else />
      <NavbarDialog />
      <div class="flex gap-4 items-center" :class="{ 'md:w-full nav--dev': isDevelopers }">
        <AppSearch :class="{ 'w-full': isDevelopers }" v-if="isDevelopers" />
        <nav v-if="isDevelopers" class="mx-1 hidden md:block">
          <ul class="hidden md:block">
            <li class="px-1 ">
              <nuxt-link to="/" class="font-large flex gap-2 items-center">
                <Icon name="heroicons-outline:home" />
              </nuxt-link>
            </li>
          </ul>
        </nav>
        <NavbarSocials />
        <UserNavigation />
      </div>
    </div>
  </header>
</template>

<script setup>
const { page } = useContent()
const path = computed(() => {
  if (page.value?._path) {
    return page.value?._path
  } else if (process.client) {
    return window.location.pathname
  }
})
const isDevelopers = computed(() => path.value?.startsWith('/developers'))
</script>

<style scoped lang="postcss">
.router-link-active {
  @apply text-primary-500;
}

.router-link-active .icon {
  @apply bg-primary-100;
}
</style>
